import Crypto from 'crypto-js';

export default {
    state: {
        isRegistering: (() => {
            var isregistering = window.localStorage.getItem('reg')
            return (isregistering) ? isregistering : null
        })(),
        isCheckingOut: false
    },
    getters: {},
    mutations: {
        update_register_state_otp(state, val) {
            state.isRegistering = val
        },
        update_register_state_checkout(state, val) {
            state.isCheckingOut = val
        }
    },
    actions: {
        getTempRegistrationData() {
            return new Promise(resolve => {
                var temp = JSON.parse(window.localStorage.getItem('reg'))
                if (temp) {
                    temp.form = JSON.parse(Crypto.AES.decrypt(temp.form, 'encryptionToken').toString(Crypto.enc.Utf8))
                    resolve(temp)
                }
                else {
                    resolve(null)
                }
            })
        },
        flagAsRegistering(context,val) {
            // Set a local value to determine if, on page refresh, the OTP form should be shown or not.
            val.form = Crypto.AES.encrypt(val.form, 'encryptionToken').toString()
            window.localStorage.setItem('reg', JSON.stringify(val))
        },
        checkForOtpMode(context, val) {
            // Flag the registration page's state to show the OTP code screen if a pending registration is ongoing.
            let commits = {isRegistering: val}
            context.commit('update_register_state_otp', commits)
        },
        discardTempRegistration() {
            window.localStorage.removeItem('reg')
        },
        registerFromCheckout(context, val) {
            // Flag the registration page's state that it is pushed from the Checkout page.
            let commits = {isCheckingOut: val}
            context.commit('update_register_state_checkout', commits)
        }
    }
}