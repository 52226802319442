import app from '@/libs/app'
import axios from '@axios'
import useJwt from "@/auth/jwt/useJwt"
export default {
    state: {
        sampleItems: [
            { key: 'teyvfe', name: 'Teyvat Fried Egg', description: 'An egg fried on one side. The yolk flows out nicely when broken. So satisfying.', brand: 'Bennett Food Station', price: 100, qty: 1 },
            { key: 'notmrw', name: 'No Tomorrow', description: 'Qiqi\'s specialty. The neat rows of ingredients seem at once exotic and delicious, and Baizhu says that Qiqi has spent a long time creating this dish. Still, once one considers that Qiqi has no sense of taste, just putting chopsticks to the food is going to take some courage.', brand: 'Qiqi\'s Cold Storage', price: 150, qty: 1 },
            { key: 'adptempt', name: 'Adeptus\' Temptation', description: 'A complex, famous type of Liyue cuisine, in which specially selected ingredients are submerged and slowly bowled into soup stock. The recipe scribbled from memory alone was enough to urge the adepti to once again return to the world of men.', brand: 'Wangmin Restaurant', price: 300, qty: 1 },
            { key: 'moramt', name: 'Mora Meat', description: 'A large hunk of minced meat packaged inside of a pastry. When you split the pastry in two, minced meat spills out. The taste of meat grease is all but gone, and the baking process has brought out the sweetness of the pastry. Such pastries are usually stamped with the word "Mora," all the better to represent wealth and fortune.', brand: 'Liyue Qixing Restaurant', price: 250, qty: 1 },
            { key: 'tianmt', name: 'Tianshu Meat', description: 'A braised meat dish. The cut of meat is a happy medium between lean and fatty, and has been braised to smoking perfection. The ingredients glisten, and are soft but not greasy. Word has it that the Tianshu of a certain generation of the Liyue Qixing invented this dish after much painstaking development.', brand: 'Uncle Tian Restaurant', price: 200, qty: 1 },
            { key: 'teabpc', name: 'Tea Break Pancake', description: 'A stack of round pancakes. A staple originating from afternoon tea in manors. Only the best berries should be picked for this dish.', brand: 'Noelle Coffee Shoppe', price: 30, qty: 1 },
            { key: 'sweetmd', name: 'Sweet Madame', description: 'Honey-roasted fowl. The honey and sweet flowers come together to compliment the tender fowl meat.', brand: 'Monstadt Specialties', price: 120, qty: 1 },
            { key: 'sunspr', name: 'Sunshine Sprat', description: 'Gently fried fish dish. Cooked with the right heat and duration to maintain freshness for a long time. Tender and flaky, delicately browned thanks to the layer of aromatic butter on top, which creates a multi-layered and unique flavor.', brand: 'Angel Bar', price: 100, qty: 1 },
            { key: 'stihonr', name: 'Sticky Honey Roast', description: 'A meat dish in a sweet honey sauce. The carrots take the gamey edge off the meat, and the sauce brings it all together sweetly. The perfect warm dish for a cold winter night.', brand: 'Amber\'s Specialty Dishes', price: 500, qty: 1 },
            { key: 'survgfi', name: 'Survival Grilled Fish', description: 'Keqing\'s specialty. This dish was not grilled. Instead, it was fried from within through the delicate application of Electro. A gentle bite reveals fragrant fish under the lightly-charred skin, its taste perfectly balanced. So, is this how she survives in the wild? You really can\'t judge a book by its cover.', brand: 'Keqing\'s Fine Dishes', price: 350, qty: 1 },
        ],
        items: []
    },
    getters: {
        getSampleItems: state => {
            return state.sampleItems
        },
        getItems: state => {
            return state.items
        }
    },
    mutations: {
        STORE_MENU_ITEMS(state, itemsToSave) {
            state.items = itemsToSave
        }
    },
    actions: {
        // Listing
        getMenuItems() {
            useJwt.setAction(app.productList)
            return new Promise(resolve => {
                axios.post(app.user, {}).then(response => {
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting getMenuItems: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        },
    }
}