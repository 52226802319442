import Vue from 'vue'
import Vuex from 'vuex'

// import Cookie from 'js-cookie';
// Store the encryption token in a secure cookie.
// Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 });

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import registrationForm from './registration-form'
import checkout from './checkout'
import menu from './menu'
import userProfile from './user-profile'
import locations from './locations'
import branches from './branches'
import products from './products'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    registrationForm,
    checkout,
    menu,
    userProfile,
    locations,
    branches,
    products,
  },
  strict: process.env.DEV,
})
