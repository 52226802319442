import Cart from '@/libs/controllers/cart-control'
import app from '@/libs/app'
import axios from '@axios'
import useJwt from "@/auth/jwt/useJwt"

export default {
    state: {
        isEmpty: false,
        itemCount: (() => {
            let count = localStorage.getItem('cart')
            return count
        })(),
        cart: []
    },
    getters: {
        getCart: state => {
            return state.cart
        },
        getItemCount: state => {
            return state.count
        }
    },
    mutations: {
        STORE_CART_ITEMS(state, cartData) {
            state.cart = cartData
        },
        update_cart_items_count(state, count) {
            state.itemCount = count
            // Persist the count by storing it locally.
            localStorage.setItem('cart', count)
        },
        update_cart_items(state, item) {
            state.cart[item.key] = (item)
        },
    },
    actions: {
        // Listing Actions
        getCartItems() {
            // We could simply use a getter for static content, but for async requests,
            // we should put the cart request here.
            const cart = new Cart
            return new Promise((resolve, reject) => {
                var storedCart = cart.getStoredCart()
                if (storedCart) {
                    resolve(storedCart)
                }
            })
        },
        getCartFromWeb(context) {
            useJwt.setAction(app.viewCart)
            return new Promise(resolve => {
                axios.post(app.user, {}).then(response => {
                    let res = response.data
                    if (res.result) {
                        let data = res.data ? res.data : []
                        if (data.length > 0) {
                            // Store the cart data and update cart count.
                            context.commit('STORE_CART_ITEMS', data)
                            context.commit('update_cart_items_count', data.length)
                        }
                        else {
                            context.commit('STORE_CART_ITEMS', [])
                            context.commit('update_cart_items_count', 0)
                            console.log(res.message)
                        }
                        resolve(res.result)
                    }
                    else {
                        console.log('Error in getCartFromWeb action: ', response)
                        resolve(res.result)
                    }
                }).catch(ex => {
                    console.log('Error requesting getCartFromWeb: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        },
        checkoutCart() {

        },

        // Item Actions
        addItemToCart(context, payload) {
            // const cart = new Cart
            // return new Promise((resolve, reject) => {
            //     try {
            //         context.commit('update_cart_items', payload)
            //         resolve({ added: true })
            //     }
            //     catch(ex) {
            //         reject({added: false, error: ex})
            //     }
            // })

            useJwt.setAction(app.addToCart)
            return new Promise(resolve => {
                axios.post(app.user, payload).then(response => {
                    context.dispatch('getCartFromWeb')
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting addItemToCart: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        },
        updateItemInCart(context, payload) {
            useJwt.setAction(app.updateCart)
            return new Promise(resolve => {
                axios.post(app.user, payload).then(response => {
                    context.dispatch('getCartFromWeb')
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting updateItemInCart: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        },
        removeItemFromCart(context, payload) {
            // const cart = new Cart
            // return new Promise((resolve, reject) => {
            //     cart.removeItem(payload).then(() => {
            //         resolve({ removed: true })
            //     }).catch(ex => {
            //         reject({ error: ex, removed: false })
            //     })
            // })

            useJwt.setAction(app.deleteCart)
            return new Promise(resolve => {
                axios.post(app.user, payload).then(response => {
                    context.dispatch('getCartFromWeb')
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting removeItemInCart: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        }
    }
}