import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return useJwt.jwtConfig.getItem() != null ? true: false
}

export const getUserData = () => useJwt.jwtConfig.getItem()

// export const canNavigate = to => to.matched.some(route => (route.meta.permission != undefined) ? route.meta.permission.indexOf(getUserData.ability) === -1: true)
export const canNavigate = to => to.matched.some(route => {
    
    // console.log();
    if(getUserData() != null && route.meta.permission != null) {
        console.log("permission:",route.meta.permission)
        console.log("getUserDataPermission:",route.meta.permission.indexOf(getUserData().permission))
        if(route.meta.permission.indexOf(getUserData().permission) !== -1) {
            return true;
        }
    }
    return false;
})

