
import Crypto from 'crypto-js';
const storageKey = 'storageKey';
const clientTypeKey = 'cltp';

// Get the encryption token from cookie or generate a new one.

export default {
  // Endpoints
  loginEndpoint: 'http://192.168.1.124:8000/api/auth/login',
  registerEndpoint: 'http://192.168.1.124:8000/api/register',
  refreshEndpoint: 'http://192.168.1.124:8000/api/auth/refresh-token',
  logoutEndpoint: 'http://192.168.1.124:8000/api/auth/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  getItem: () => {
    // Get the store from local storage.
    const store = window.localStorage.getItem(storageKey)

    if (store) {
      try {
        // Decrypt the store retrieved from local storage
        // using our encryption token stored in cookies.
        const bytes = Crypto.AES.decrypt(store, 'encryptionToken')

        return JSON.parse(bytes.toString(Crypto.enc.Utf8))
      } catch (e) {
        // The store will be reset if decryption fails.
        window.localStorage.removeItem(storageKey)
      }
    }

    return null
  },
  getAuthItem: () => {
    // Get the custom client-type store from local storage.
    const store = window.localStorage.getItem(clientTypeKey)

    if (store) {
      try {
        const bytes = Crypto.AES.decrypt(store, 'encryptionToken')
        return JSON.parse(bytes.toString(Crypto.enc.Utf8))
      }
      catch (ex) {
        window.localStorage.removeItem(clientTypeKey)
      }
    }

    return null
  },
  setItem: (value) => {
    // Encrypt the store using our encryption token stored in cookies.
    const store = Crypto.AES.encrypt(value, 'encryptionToken').toString()

    // Save the encrypted store in local storage.
    return window.localStorage.setItem(storageKey, store)
  },

  overrideActionHeader: (value) => {
    // Get the store from local storage.
    const store = window.localStorage.getItem(storageKey)

    if (store) {
      try {
        // Decrypt the store retrieved from local storage
        const bytes = Crypto.AES.decrypt(store, 'encryptionToken')

        let jsonData = JSON.parse(bytes.toString(Crypto.enc.Utf8))
        jsonData.action_header = value

        // Encrypt the store using our encryption token stored in cookies.
        jsonData = Crypto.AES.encrypt(JSON.stringify(jsonData), 'encryptionToken').toString()

        // Save the encrypted store in local storage.
        return window.localStorage.setItem(storageKey, jsonData)
      } catch (e) {
        // The store will be reset if decryption fails.
        window.localStorage.removeItem(storageKey)
      }
    }
    return null
  },

  overrideAuthActionHeader: (value) => {
    // Overrides the App-Action-Auth header value.
    // Get the store from local storage.
    const store = window.localStorage.getItem(clientTypeKey)
    if (store) {
      try {
        // Decrypt the store retrieved from local storage
        const bytes = Crypto.AES.decrypt(store, 'encryptionToken')

        let jsonData = JSON.parse(bytes.toString(Crypto.enc.Utf8))
        jsonData.auth_action_header = value

        // Encrypt the store using our encryption token stored in cookies.
        jsonData = Crypto.AES.encrypt(JSON.stringify(jsonData), 'encryptionToken').toString()

        // Save the encrypted store in local storage.
        return window.localStorage.setItem(clientTypeKey, jsonData)
      } catch (e) {
        // The store will be reset if decryption fails.
        window.localStorage.removeItem(clientTypeKey)
      }
    }
    else {
      // Upon logging in, there will be no token available yet.
      // This will set a custom client-type key in the storage.
      let jsonData = {}
      jsonData.auth_action_header = value
      jsonData = Crypto.AES.encrypt(JSON.stringify(jsonData), 'encryptionToken').toString()
      return window.localStorage.setItem(clientTypeKey, jsonData);
    }
    return null
  },

  removeItem: () => window.localStorage.removeItem(storageKey),
}
