import app from '@/libs/app'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
export default {
    state: {
        sampleProfile: {
            first_name: 'Lunasa',
            last_name: 'Prismriver',
            username: 'lunasaprismriver',
            mobile: '639053999040',
            email: 'lunasa.prismriver@gmail.com',
        }
    },
    getters: {
        getSampleProfile: state => {
            return state.sampleProfile
        }
    },
    mutations: {},
    actions: {}
}