export default [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Dashboard',
        pageIcon: 'UserIcon',
        permission: [1,2],
        // breadcrumb: [
        //   {
        //     text: 'Dashboard',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/apps/profile/Profile.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'My Profile',
        pageIcon: 'UserIcon',
        permission: [1,2],
        // breadcrumb: [
        //   {
        //     text: 'My Profile',
        //     active: true
        //   }
        // ]
      }
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/apps/orders/Orders.vue'),
      meta: {
        resouce: 'ACL',
        pageTitle: 'Orders',
        pageIcon: 'ArchiveIcon',
        navHeader: 'Orders',
        navGroupIcon: 'ArchiveIcon',
        permission: [1,2]
      }
    },
    {
      path: '/checkout',
      name: 'cart-checkout',
      component: () => import ('@/views/apps/checkout/Checkout.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Checkout',
        pageIcon: 'ShoppingCartIcon',
        navHeader: 'Checkout',
        navGroupIcon: 'ShoppingCartIcon',
        permission: [2]
      }
    },
    {
      path: '/inventory',
      name: 'admin-inventory',
      component: () => import ('@/views/apps/admin/inventory/Inventory.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Inventory',
        pageIcon: 'ListIcon',
        navHeader: 'Inventory',
        navGroupIcon: 'ListIcon',
        permission: [1]
      }
    },
    {
      path: '/stores',
      name: 'admin-stores',
      component: () => import ('@/views/apps/admin/stores/Stores.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Stores',
        pageIcon: 'ShoppingBagIcon',
        navHeader: 'Stores',
        navGroupIcon: 'ShoppingBagIcon',
        permission: [1]
      }
    },
    {
      path: '/user/add-user',
      name: 'add-user',
      component: () => import('@/views/forms/AddUser.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Add User',
        pageIcon: 'UserPlusIcon',
        navHeader: 'Account Navigation',
        navGroupTitle: 'User',
        navGroupIcon: 'UserIcon',
        permission: [1,2],
        // breadcrumb: [
        //   {
        //     text: 'Add User',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/user/lists',
      name: 'user',
      component: () => import('@/views/content/Users.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'User List',
        pageIcon: 'UsersIcon',
        navHeader: 'Account Navigation',
        navGroupTitle: 'User',
        navGroupIcon: 'UserIcon',
        permission: [1,2],
        // breadcrumb: [
        //   {
        //     text: 'User List',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/views/Tickets.vue'),
      meta: {
        resource: 'ACL',
        pageTitle: 'Support Ticket',
        pageIcon: 'BookIcon',
        navHeader: 'OTHERS',
        permission: [1,2],
        // breadcrumb: [
        //   {
        //     text: 'Support Ticket Page',
        //     active: true,
        //   },
        // ],
      },
    },
  ]
