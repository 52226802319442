export default [
    {
      path: '/',
      name: 'landing-sample',
      component: () => import('@/views/pages/landing/Landing.vue'),
      props: true,
      meta: {
        layout: 'full',
        requiresAuth: false,
      }
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'page-auth-register',
      props: true,
      component: () => import('@/views/pages/authentication/registration/Register.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
  ]