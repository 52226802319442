import { Object } from "core-js"
import store from '@/store/index'
const localCartKey = 'temp-cart'

export default class Cart {
    getStoredCart() {
        var cart = JSON.parse(window.localStorage.getItem(localCartKey))
        if (!cart) {
            store.commit('update_cart_items_count', 0)
            return cart
        }
        else {
            return cart
        }
    }

    createCart(item) {
        return new Promise(resolve => {
            // Only called if no cart is present in the storage.
            let setItem = []
            let incomingItem = {}
            item.qty = 1
            incomingItem[item.id] = item
            setItem.push(JSON.stringify(incomingItem))
            window.localStorage.setItem(localCartKey, setItem)
            store.commit('update_cart_items_count', store.state.checkout.itemCount + 1)
        });
    }

    updateCart(incomingItem, fromCart) {
        return new Promise(resolve => {
            var cart = this.getStoredCart()
            var matchFound = false

            if (cart) {
                // Check if incoming item is in cart already.
                let matchItem = [cart]
                let matched
                matchItem.some(item => {
                    Object.keys(item).forEach(key => {
                        if (key == incomingItem.id) {
                            matchFound = true
                            matched = item[key]
                        }
                    })
                })
                // If matched, update its entry in the cart.
                if (matchFound) {
                    if (fromCart) {
                        matched.qty = incomingItem.qty
                    }
                    else {
                        matched.qty += incomingItem.qty
                    }
                    cart[matched.id] = matched
                }
                // If not, add it to the cart.
                if (!matchFound) {
                    incomingItem.qty = 1
                    cart[incomingItem.id] = incomingItem
                    store.commit('update_cart_items_count', store.state.checkout.itemCount + 1)
                }
                resolve(window.localStorage.setItem(localCartKey, JSON.stringify(cart)))
            }
            else {
                resolve({error: 'Cart not found.'})
            }
        })
    }

    removeItem(outgoingItem) {
        return new Promise(resolve => {
            let cart = this.getStoredCart()
            delete (cart[outgoingItem])
            store.commit('update_cart_items_count', store.state.checkout.itemCount - 1)
            resolve(window.localStorage.setItem(localCartKey, JSON.stringify(cart)))
        })
    }

    checkoutCart() {
        
    }
}