export default {
  // Endpoints
  baseURL: process.env.MIX_APP_URL,
  baseLanding: 'http://tiongbahru.net',

  // Registration
  requestOtp: '/auth/verify-mobile',
  register: '/auth/register',

  // Users
  user: '/app',
  admin: '/admin',
  public: '/api/public',

  // App-Action-Headers
  viewCart: 'ViewCart',
  deleteCart: 'DeleteCart',
  updateCart: 'UpdateCart',
  addToCart: 'AddCart',
  productList: 'ProductList',
  selectAddress: 'SelectAddress',
  addProduct: 'AddProduct',
  uploadImage: 'UploadImage',
}
