import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        const actionHeaderMethod = this.getAction()
        const authActionHeaderMethod = this.getAuthAction()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers = { 'App-Action-Header': `${actionHeaderMethod}`, 'App-Action-Auth': `${authActionHeaderMethod}`}
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        else {
          // If no token is present, append only the client/auth header.
          let loginAuthActionHeader = this.getAuthAction()
          config.headers = { 'App-Action-Auth': `${loginAuthActionHeader}` }
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        
        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.onAccessTokenFetched(r.data.accessToken)
            })
            this.removeToken()
            if(router.currentRoute.name != 'auth-login') {
              router.push({name: 'auth-login'})
            }
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          
          return retryOriginalRequest
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return this.jwtConfig.getItem() == null
      ? null
      : this.jwtConfig.getItem().access_token
  }

  getAction() {
    return this.jwtConfig.getItem() == null
      ? null
      : this.jwtConfig.getItem().action_header
  }

  // Gets the custom client-action stored value.
  getAuthAction() {
    return (this.jwtConfig.getAuthItem() == null)
      ? null
      : this.jwtConfig.getAuthItem().auth_action_header
  }

  getPermission() {
    return this.jwtConfig.getItem() == null
      ? null
      : this.jwtConfig.getItem().permission
  }

  getRefreshToken() {
    return this.jwtConfig.getItem() == null
      ? null
      : this.jwtConfig.getItem().access_token
  }

  setToken(value) {
    this.jwtConfig.setItem(value)
  }

  // Sets the custom client-action stored value.
  setAuthAction(value) {
    this.jwtConfig.overrideAuthActionHeader(value)
  }

  setAction(value) {
    this.jwtConfig.overrideActionHeader(value)
  }

  removeToken() {
    this.jwtConfig.removeItem()
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
