import app from '@/libs/app'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
export default {
    state: {
        productStatuses: [
            { text: 'Select a product status', value: '', disabled: true },
            {text: 'Active', value: 'active', disabled: false},
            {text: 'For Pricing', value: 'for pricing', disabled: false},
            {text: 'Sold Out', value: 'sold out', disabled: false},
            {text: 'On Sale', value: 'on sale', disabled: false}
        ],
        tempfile: null
    },
    getters: {
        getProductStatuses: state => {
            return state.productStatuses
        },
        getTempFile: state => {
            if (state.tempfile) {
                return state.tempfile
            }
            else {
                return window.localStorage.getItem('temp')
            }
        }
    },
    mutations: {
        SET_TEMPFILE(state, data) {
            var localTempfile = window.localStorage.getItem('temp')
            if (!localTempfile) {
                window.localStorage.setItem('temp', data)
                state.tempfile = data
            }
            else {
                state.tempfile = localTempfile
            }
        },
        REMOVE_TEMPFILE(state, data) {
            if (data.result) {
                window.localStorage.removeItem('temp')
                state.tempfile = null
            }
        }
    },
    actions: {
        uploadProductPhoto(context, payload) {
            useJwt.setAction(app.uploadImage)
            return new Promise(resolve => {
                axios.post(app.admin, payload).then(response => {
                    context.commit('SET_TEMPFILE', response.data.data.file)
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting file upload: ', ex)
                    resolve(ex)
                })
            }).catch(ex => {
                console.log('Error executing file upload: ', ex)
            })
        },
        addProduct(context, payload) {
            useJwt.setAction(app.addProduct)
            return new Promise(resolve => {
                axios.post(app.admin, payload).then(response => {
                    context.commit('REMOVE_TEMPFILE', response.data)
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting addProduct: ', ex)
                    resolve(ex)
                })
            }).catch(ex => {
                console.log('Error executing addProduct: ', ex)
            })
        },
    }
}