import app from '@/libs/app'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
export default {
    state: {
        provinces: [],
        cities: [],
        barangays: [],
        sampleAddress: {
            province: 'Cavite',
            city: 'Noveleta',
            brgy: 'San Antonio I',
            zip: '4105',
            given_address: 'Unit 1B Master Apartments, M. Salud Street',
            address_line1: 'San Antonio I, Noveleta',
            address_line2: 'Cavite 4105'
        },
        sampleAddressSet: [
            {
                id: 1,
                active: true,
                disabled: true,
                province: {value: '0421', text: 'Cavite'},
                city: {value: '042116', text: 'Noveleta'},
                brgy: {value: '042116004', text: 'San Antonio I'},
                zip: '4105',
                given_address: 'Unit 1B Master Apartments, M. Salud Street',
                address_line1: 'San Antonio I, Noveleta',
                address_line2: 'Cavite 4105',
                address_type: 'home',
            },
            {
                id: 2,
                active: false,
                disabled: true,
                province: {value: '0421', text: 'Cavite'},
                city: {value: '042109', text: 'Imus City'},
                brgy: {value: '042109050', text: 'Bucandala III'},
                zip: '4106',
                given_address: 'B7 L9 P2 Mary Cris Homes',
                address_line1: 'Bucandala III, Imus City',
                address_line2: 'Cavite 4106',
                address_type: 'home',
            },
        ]
    },
    getters: {
        getProvinces: state => {
            return state.provinces
        },
        getCities: state => {
            return state.cities
        },
        getBarangays: state => {
            return state.barangays
        },
        getSampleAddress: state => {
            return state.sampleAddress
        },
        getSampleAddressSet: state => {
            return state.sampleAddressSet
        }
    },
    mutations: {
        STORE_PROVINCES(state, data) {},
        STORE_CITIES(state, data) {},
        STORE_BARANGAYS(state, data) {}
    },
    actions: {
        getLocationFragment(context, payload) {
            useJwt.setAction(app.selectAddress)
            return new Promise(resolve => {
                axios.post(app.user, payload).then(response => {
                    resolve(response)
                }).catch(ex => {
                    console.log('Error requesting getLocationFragment: ', ex)
                    resolve({result: false, message: ex})
                })
            })
        }
    }
}