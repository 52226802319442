import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserData, canNavigate } from '@/auth/utils'
import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'landing-sample' } },
        ...apps,
        ...pages,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// router.afterEach(() => {
//     // Remove initial loading
//     const appLoading = document.getElementById('loading-bg')
//     if (appLoading) {
//         appLoading.style.display = "none";
//     }
// })

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Allow Pages
        if (!isLoggedIn && to.name == 'page-auth-register' || to.name == 'landing-sample') return next()
        // Redirect to login if not logged in
        if (!isLoggedIn && to.name != 'auth-login') return next({name: 'auth-login'})
        // If logged in => not authorized
        if (isLoggedIn && to.name != 'misc-not-authorized') return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        return next(userData.redirectTo)
    }

    return next()
})

export default router
