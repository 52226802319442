import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // http://192.168.1.124:8000/api/
  // http://app-tiongbahru.dynamicmarkups.com
  baseURL: 'http://192.168.1.124:8000/api/',
  // timeout: 1000,
  headers: {
    // 'X-Custom-Header': 'foobar'
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
